<template>
	<div id="home">
		<vue-headful
            title="Michael Vincent - Un jour je me suis choisi"
            description="Michaël Vincent a passé sa jeunesse dans les rues de Montréal à consommer et à vendre de la drogue pour les gangs de rue et les motards."
			og:description="Michaël Vincent a passé sa jeunesse dans les rues de Montréal à consommer et à vendre de la drogue pour les gangs de rue et les motards."
        />
		<header>
			<div id="intro">
				<div  class="rellax" data-rellax-speed="1">
					<img id="unjourlogo" src="@/assets/images/unjour.svg"/>
					<div id="intro-links">
						<div>
							<a href="" class="js-modal-btn" data-video-id="nUiMIUFXsB4"><img src="@/assets/images/play_btn.png"/> <span>Voir la vidéo</span></a>
						</div>
						<div>
							<a href="https://www.facebook.com/Un-jour-je-me-suis-choisi-118798982859930/" target="_blank"><span><b>Suivez-moi sur </b>Facebook</span> <i class="fab fa-facebook-square"></i></a>
						</div>
					</div>
				</div>
			</div>
		</header>
		<section id="aboutmv">
			<div class="container">
				<div class="row">
					<div class="col-4">
						<div class="imgreveal rellax" data-rellax-speed=".5"><img width="100%" src="@/assets/images/photo_intro.jpg"/></div>
					</div>
					<div class="col-7 preffix-1 textreveal">
						<p class="intro yellow">Michaël Vincent a passé sa jeunesse dans les rues de Montréal à consommer et à vendre de la drogue pour les gangs de rue et les motards. </p>
						<p>À 11 ans, il a commencé à sniffer de la coke et, à 14 ans, il avait déjà essayé toutes les drogues qui existaient. Dans la rue, il a été victime de violence et a été témoin de tentatives de meurtre; l’enfer de la drogue était devenu son mode de vie. Mais UN JOUR, IL S’EST CHOISI. </p>
						<p>Venez assister à cette conférence, qui se veut une belle histoire de survie et de développement personnel. Il s’agit en quelque sorte d’une thérapie qui permet de mieux comprendre comment un toxicomane a pu devenir l’un des hommes d’affaires les plus respectés du Centre-du-Québec.</p>
						<router-link class="btn btn-round btn-light m-t-40 inline-block" to="/qui-est-michael-vincent">En savoir plus sur Michaël</router-link>
					</div>
				</div>
			</div>
		</section>
		<section id="photos">
			<div class="container">
				<div class="imgreveal rellax" data-rellax-speed=".5" id="img01"><img src="@/assets/images/01.png" /></div>
				<div class="imgreveal" id="img02"><a href="" class="js-modal-btn" data-video-id="OsraZEmKHU8"><img src="@/assets/images/02.png"/></a></div>
				<div class="imgreveal" id="img03"><img src="@/assets/images/03.png"/></div>
				<div class="imgreveal rellax" data-rellax-speed=".5" id="img04"><img src="@/assets/images/04.jpg"/></div>
			</div>
		</section>
		<section id="calendrier" class="blockreveal">
			<div class="container-small">
				<div id="dates">
					<h2><span>Prochaines<br/>conférences</span><router-link class="btn btn-round btn-light inline-block" to="/un-jour-je-me-suis-choisi">La conférence</router-link></h2>
					<div v-if="eventslist">
					<div class="calendar">
						<div v-for="event in eventslist" v-bind:key="event.id">
							<div>
								<img src="@/assets/images/calendar.png"/>
								<p>
									<strong>{{ event.fields.Date }}</strong><br>
									{{ event.fields.Heure }}
								</p>
							</div>
							<div>
								<img src="@/assets/images/pointer.png"/>
								<p>
									<strong>{{ event.fields.Lieu }}</strong><br>
									{{ event.fields.Ville }}
								</p>
							</div>
							<div>
								<a v-bind:href="event.fields.Lien" target="_blank"><span>Réservez votre billet</span> <img src="@/assets/images/arrow.png"/></a>
							</div>
						</div>
					</div>
					</div>
					<div v-else class="p-t-40">
						Aucune date n’est prévue à ce jour.
					</div>
				</div>
			</div>
		</section>
		<Footer/>
	</div>
</template>

<script>
import Footer from '../components/Footer'
import axios from 'axios'
import $ from "jquery"
import Init from '../components/Init'
import ModalVideo from 'modal-video'
import 'modal-video/css/modal-video.min.css'
import Rellax from 'rellax'
//import { smoothScroll } from '../assets/js/smoothScroll'

export default {
	name: 'home',
	data: function(){
		return{
			eventslist: null
		}
	},
	header:{
		title: 'Accueil'
	},
	components:{
		Footer
	},
	mounted() {
		Init.methods.reveal()

		new Rellax('.rellax');
		new ModalVideo('.js-modal-btn');

		$(window).scroll(function(){
			$("header #intro").css("opacity", 1 - $(window).scrollTop() / $(window).height());
		});

		// Init variables
		var ref = this;
		var app_key = process.env.VUE_APP_AIRTABLEAPIKEY;
		var app_id = process.env.VUE_APP_AIRTABLEAPPID;
		var config = {
			headers: {'Authorization': "Bearer " + app_key}
		};

		axios.get(
			"https://api.airtable.com/v0/"+app_id+"/Conf%C3%A9rences?view=Grid%20view",
			config
		).then(function(response){
			//this.eventslist = response.data.records
			if(response.data.records.length > 0){
				ref.eventslist = response.data.records
			}
			else{
				ref.eventslist = null
			}
			//console.log(response.data.records)
		})/*.catch(function(error){
			console.log(error)
		})*/
	}
}
</script>
