<template>
	<div id="nousjoindre">
		<vue-headful
            title="Michael Vincent - Me joindre"
            description="Si vous souhaitez ograniser une conférence dans votre établissement, contactez-nous afin de valider les disponibilités."
			og:description="Si vous souhaitez ograniser une conférence dans votre établissement, contactez-nous afin de valider les disponibilités."
        />
		<div class="container contact">
			<div class="row">
				<div class="col-5">
					<h1>Nous<br>contacter</h1>
				</div>
				<div class="col-5 preffix-1">
					<p>Si vous souhaitez organiser une conférence dans votre établissement, contactez-nous afin de vérifier les disponibilités.</p>
					<form action="https://usebasin.com/f/459a7cfadfdf" method="POST" id="contactform">
						<input type="text" id="nom" name="nom" placeholder="Votre nom (obligatoire)" required>
						<input type="email" id="courriel" name="courriel" placeholder="Votre courriel (obligatoire)" required>
						<input type="text" id="phone" name="phone" placeholder="Votre numéro de téléphone">
						<textarea name="message" id="message" placeholder="Votre message"></textarea>
						<div>
							<input type="submit" class="btn btn-round btn-light inline-block" value="Envoyer"/>
							<div id="form-message"></div>
						</div>
						<div id="formloading"><img src="../assets/images/spinner.gif"/></div>
					</form>
				</div>
			</div>
			
		</div>
		<Footer/>
	</div>
</template>
<script>
import Footer from '../components/Footer'
import $ from "jquery"
//import { smoothScroll } from '../assets/js/smoothScroll'

export default{
	name: 'nousjoindre',
	components:{
		Footer
	},
	mounted() {
		$(function(){
			//smoothScroll('#nousjoindre')
		})

		$(window).scroll(function(){
			$("header img").css("opacity", 1 - $(window).scrollTop() / $(window).height());
		});

		var form = document.getElementById("contactform");
		var formMessage = document.getElementById("form-message");
		var formLoading = document.getElementById("formloading");

		form.onsubmit = function(event) {
			event.preventDefault();
			formLoading.style.display = 'block';
			var formData = new FormData(form);
			
			var xhr = new XMLHttpRequest();
			xhr.open("POST", form.action, true);
			xhr.onload = function() {
				var response = JSON.parse(xhr.response);
				formLoading.style.display = 'none';
				if (xhr.status === 200) {
					form.innerHTML = "Votre message à été envoyé. Merci!";
				} 
				else {
					formMessage.innerHTML = "Error: " + response.error;
				}
			};
			xhr.send(formData);
		};
	},
}
</script>
