<template>
  <div id="topnav">
        <router-link id="logo" to="/"><img src="@/assets/images/logo.svg"/></router-link>
        <nav id="main-nav">
          <button class="hamburger hamburger--slider" type="button" id="main-nav-trigger" @click="toggleMenu">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
          <div id="main-nav-desktop">
            <router-link to="/qui-est-michael-vincent">Qui est Michaël Vincent?</router-link>
            <router-link to="/un-jour-je-me-suis-choisi">Un jour, je me suis choisi.</router-link>
            <router-link to="/mcv">MCV</router-link>
            <router-link class="btn btn-round btn-light" to="/contact">Contact</router-link>
          </div>
          <div id="main-nav-mobile">
            <div id="inner">
              <router-link to="/qui-est-michael-vincent">Qui est Michaël Vincent?</router-link>
              <router-link to="/un-jour-je-me-suis-choisi">Un jour, je me suis choisi.</router-link>
              <router-link to="/mcv">MCV</router-link>
              <router-link to="/contact">Contact</router-link>
              <a id="fb" href="https://www.facebook.com/Un-jour-je-me-suis-choisi-118798982859930/" target="_blank">Suivez-moi sur <i class="fab fa-facebook-square"></i></a>
            </div>
          </div>
        </nav>
  </div>
</template>

<script>
import $ from "jquery"

export default {
  name: 'Nav',
  methods: {
    toggleMenu(){
      var element = document.getElementById("main-nav-mobile");
      element.classList.toggle("open");
      document.getElementById('main-nav-trigger').classList.toggle('is-active');
    }
  },
  mounted() {
    $(window).scroll(function(){
      if($(window).scrollTop() > 400){
        $('#topnav').addClass('scrolled');
      }
      else{
        $('#topnav').removeClass('scrolled');
      }
    });
    $('#main-nav-mobile > #inner > a:not(#fb)').click(function(e){
      e.preventDefault();
      var element = document.getElementById("main-nav-mobile");
      element.classList.toggle("open");
      document.getElementById('main-nav-trigger').classList.toggle('is-active');
    });
  }
}
</script>
