import Vue from 'vue'
//import VuePageTransition from 'vue-page-transition'
import vueHeadful from 'vue-headful'
import App from './App.vue'
import router from './router'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './assets/sass/app.scss'
import './assets/vendors/hamburgers.css'

//Vue.use(VuePageTransition)
Vue.component('vue-headful', vueHeadful);
Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
