<template>
	<div id="app">
		<div class="loading">
			<div class="counter">
				<p><img src="@/assets/images/logo.svg" /></p>
				<hr/>
			</div>
		</div>
		<Nav/>
		<transition name="fade" mode="out-in">
			<router-view/>
		</transition>
	</div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>

<script>
import Nav from './components/Nav'
import Init from './components/Init'

export default{
	name: 'app',
	components:{
		Nav
	},
	mounted() {
		Init.methods.loading()
	}
}
</script>