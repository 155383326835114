<template>
	<div id="mvcevolution">
		<vue-headful
            title="Michael Vincent - Un jour je me suis choisi, La conférence"
            description="« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle."
			og:description="« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle."
        />
		<header>
			<div class="container">
				
			</div>
		</header>
		<section id="mvcevolutionpresentation">
			<div class="container-small">
                <div class="row">
                    <div class="col-12">
                        <p class="fs-24 lh-37">Les gens me connaissent comme Michaël Vincent, l’homme d’affaires et le conférencier, mais il y a une grande partie de mon être, de mes rêves et de mes ambitions qui est toujours cachée. Par le projet MCV, je vous dévoile au grand jour, en toute humilité, une partie cachée de moi, une dimension créative qui vient du plus profond de mon cœur et de mon âme. Ça fait longtemps que j’y travaille, que j’hésite, que je crains la réaction des gens, mais c’est décidé, je me lance. J’espère de tout cœur que vous allez aimer.</p>
                    </div>
                    <div class="col-6">
                        <p class="m-t-100 m-t-30-mobile fs-16 lh-30">Vous trouverez sur cette page mes différents projets de création. Des chansons que j’ai écrites, qui proviennent directement de mes tripes et d’autres conçues par des gens qui m’inspirent que j’ai eu un immense plaisir à interpréter. Ces oeuvres vous permettront, je l’espère, de faire grandir cette force en vous. Écoutez les paroles et partagez-les afin qu'individuellement et tous ensemble on puisse être toujours plus forts. Je souhaite que mes chansons vous permettent de croître, de devenir une meilleure version de vous-même. J’aimerais qu’elles soient pour vous une grande source d’inspiration.</p>
                    </div>
                    <div class="col-6 align-right">
                        <img src="@/assets/images/mvcevolution.jpg" />
                    </div>
                    <div class="col-10 preffix-1 suffix-1 m-t-100">
                        <p class="quote lh-40"><strong><i>Notre planète est en pleine évolution<br>Notre vie est en pleine évolution<br>Notre propre conscience est en pleine évolution</i></strong></p>
                    </div>
                </div>
            </div>
		</section>
        <section id="clips">
            <div class="container-small">
                <div class="row">
                    <div class="col-12">
                        <h2 class="align-center fs-80 fs-40-mobile m-b-100 m-b-50-mobile">LES CLIPS</h2>
                    </div>
                    <div class="col-6">
                        <a href="" class="js-modal-btn block align-center" data-video-id="0HKIMXHOi04"><img src="@/assets/images/mcv_evolution.png" /></a>
                    </div>
                    <div class="col-5 preffix-1">
                        <h3 class="fs-30 m-t-40-mobile">ÉVOLUTION</h3>
                        <p class="m-t-40 m-b-40">Changer, évoluer et s’améliorer est à la portée de tous et chacun. Il suffit de se regarder dans le miroir, de bien évaluer ses forces et ses limites et de faire preuve de persévérance. Je souhaite que cette chanson ait l’effet d’une locomotive et qu’elle vous donne la motivation nécessaire pour réaliser vos rêves!</p>
                        <p class="yellow"><i>« L’évolution de la conscience de la personne<br>Chaque individu a l’ pouvoir de s’rlever<br>Faut juste se’rgarder dans l’miroir pis dire qu’on peut<br>Faut juste que tu t’accroches fort pis que tu’l veuilles »</i></p>
                    </div>
                </div>
                <div class="row" style="margin-top: 40px;">
                    <div class="col-6">
                        <a href="" class="js-modal-btn block align-center" data-video-id="lzHmJu6cfqU"><img src="@/assets/images/mcv_je_serai_la.png" /></a>
                        <img src="@/assets/images/listento.png" usemap="#listento" />
                        <map name="listento">
                            <area shape="rect" title="Spotify" coords="0,0,127,51" href="https://open.spotify.com/album/0L3ARokxFo9aWwNuzpZJaf" target="_blank" />
                            <area shape="rect" title="Apple Music" coords="127,0,250,51" href="https://music.apple.com/ca/album/1528220444?uo=4&app=music&at=1l3vpUI&lId=22222755&cId=none&sr=2&src=Linkfire&itscg=30440&itsct=catchall_p2&ct=LFV_9333dfa061605c1618601eb0c1664f9a&ls=1" target="_blank" />
                            <area shape="rect" title="iTune Store" coords="250,0,375,51" href="https://itunes.apple.com/ca/album/1528220444?uo=4&app=itunes&at=1l3vpUI&lId=22222755&cId=none&sr=3&src=Linkfire&itscg=30440&itsct=catchall_p3&ct=LFV_9333dfa061605c1618601eb0c1664f9a&ls=1" target="_blank" />
                            <area shape="rect" title="Google Play" coords="375,0,500,51" href="https://play.google.com/store/music/album/MCV_Je_serai_l%C3%A0_feat_Striger?id=B2sc5h4khdynilbmg4ucjdo2kyu&PCamRefID=LFV_9333dfa061605c1618601eb0c1664f9a" target="_blank" />
                            <area shape="rect" title="Deezer" coords="0,51,177,94" href="https://www.deezer.com/album/167872212?app_id=140685&utm_source=partner_linkfire&utm_campaign=9333dfa061605c1618601eb0c1664f9a&utm_medium=Original&utm_term=objective-stream&utm_content=album-167872212" target="_blank" />
                            <area shape="rect" title="Napster" coords="177,51,300,90" href="https://www.napster.com/artist/mcv/album/je-serai-la" target="_blank" />
                            <area shape="rect" title="Tidal" coords="300,51,500,94" href="http://listen.tidalhifi.com/track/152304008" target="_blank" />
                        </map>
                    </div>
                    <div class="col-5 preffix-1">
                        <h3 class="fs-30 m-t-40-mobile">JE SERAI LÀ</h3>
                        <p class="m-t-40 m-b-40">Réalisée en collaboration avec Striger, "Je serai là" constitue un véritable hymne à la vie. Chanson par excellence de l’été et du beau temps, elle encourage les gens à oublier leurs soucis et à profiter du temps qui passe. Profites-en, vis ta vie au maximum, car je serai toujours là pour toi.</p>
                        <p class="yellow"><i>
                            « Tu peux compter sur tes amis tu peux compter sur ta famille! -They will be there<br />
                            <br />
                            (Bridge)<br />
                            Tu peux compter sur tes amis tu peux compter sur ta famille! -They will be there<br />
                            <br />
                            Tu peux rev'nir dans ton passé ou tu peux choisir d'avancé! -By your side<br />
                            <br />
                            Tu peux les laissés t'emporter ou tu peux juste te redresser -You not alone<br />
                            <br />
                            Cé le moment de vérité le moment de te retrouvé! »
                        </i></p>
                    </div>
                </div>
                <div class="row" style="margin-top: 40px;">
                    <div class="col-6">
                        <a href="" class="js-modal-btn block align-center" data-video-id="ZvgzIDNox3M"><img src="@/assets/images/video_situentends.png" /></a>
                    </div>
                    <div class="col-5 preffix-1">
                        <h3 class="fs-30 m-t-40-mobile">SI TU M'ENTENDS</h3>
                    </div>
                </div>
                <div class="row" style="margin-top: 40px;">
                    <div class="col-6">
                        <a href="" class="js-modal-btn block align-center" data-video-id="QeGayV9H31Q"><img width="100%" src="@/assets/images/ensemble.png" /></a>
                    </div>
                    <div class="col-5 preffix-1">
                        <h3 class="fs-30 m-t-40-mobile">Ensemble</h3>
                    </div>
                </div>
            </div>
        </section>
        <section id="revuesdepressemvc">
			<div class="container-small">
                <div class="row">
                    <div class="col-8 suffix-4">
                        <h3 class="yellow">Revues de presse</h3>
                        <ul>
                            <li v-for="pressemvc in presseListMVC" v-bind:key="pressemvc.id">
                                <a v-bind:href="pressemvc.fields.Lien" target="_blank">
                                    <u>{{ pressemvc.fields.Titre }}</u>
                                    {{ pressemvc.fields.SmallTxt }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
			</div>
		</section>
		<section class="biglink">
			<div class="container">
				<router-link to="/contact">Nous contacter</router-link>
			</div>
		</section>
		<Footer/>
	</div>
</template>

<script>
import Footer from '../components/Footer'
import $ from "jquery"
import Init from '../components/Init'
import ModalVideo from 'modal-video'
import 'modal-video/css/modal-video.min.css'
import Rellax from 'rellax'
//import { smoothScroll } from '../assets/js/smoothScroll'
import axios from 'axios'

export default {
    name: 'mcvevolution',
    data: function(){
		return{
			presseListMVC: null
		}
	},
	components:{
		Footer
	},
	mounted() {			
		$(function(){
			setTimeout(() => {
				//smoothScroll('#unjourjemesuischoisi');
			}, 100);
			
		});

		Init.methods.reveal()
		new Rellax('.rellax');
		new ModalVideo('.js-modal-btn');

		$(window).scroll(function(){
			$("header img").css("opacity", 1 - $(window).scrollTop() / $(window).height());
        });
        
        // Init variables
		var ref = this;
		var app_key = process.env.VUE_APP_AIRTABLEAPIKEY;
		var app_id = process.env.VUE_APP_AIRTABLEAPPID;
		var config = {
			headers: {'Authorization': "Bearer " + app_key}
		};

		axios.get(
			"https://api.airtable.com/v0/"+app_id+"/Revues%20de%20presse%20mvcevolution?view=Grid%20view",
			config
		).then(function(response){
			ref.presseListMVC = response.data.records
		})/*.catch(function(error){
			console.log(error)
		})*/
	}
}
</script>