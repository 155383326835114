<template>
	<div id="unjourjemesuischoisi">
		<vue-headful
            title="Michael Vincent - Un jour je me suis choisi, La conférence"
            description="« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle."
			og:description="« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle."
        />
		<header>
			<div class="container">
				<img src="@/assets/images/unjour.svg" class="rellax" data-rellax-speed=".7" />
			</div>
		</header>
		<section id="unjourpresentation">
			<div class="container">
				<img class="float-right m-l-40 m-b-40 imgreveal" src="@/assets/images/mv_unjour.jpg" />
				<p class="intro yellow"><i>« Un jour, me me suis choisi »</i> est un véritable message d’espoir s’adressant à toutes les personnes désirant vivre une croissance personnelle, des gens d’affaires aux jeunes de nos écoles en passant par les professionnels issus de divers milieux.
				</p>
				<p>Dans le cadre de cette conférence, Michaël Vincent raconte son début de vie mouvementée, alors qu’il
					a été confronté à l’enfer de la drogue et du crime organisé à un tout jeune âge. Il explique comment
					il s’en est sorti, ce qui a fait en sorte qu’UN JOUR, IL S’EST CHOISI.</p>

				<p>Michaël raconte également ce qui l'a mené à la création de Séjour 21, un projet qui permet chaque année
					à une dizaine de personnes de vivre une expérience évolutive dans l'être faisant en sorte qu’elles puissent franchir les obstacles de leur vie personnelle en vue de se réaliser pleinement. </p>
			</div>
			<a href="" class="m-t-100 js-modal-btn block align-center" data-video-id="nUiMIUFXsB4"><img src="@/assets/images/video_unjour.jpg" /></a>
		</section>
		<section class="biglink">
			<div class="container">
				<router-link to="/mcv">MCV</router-link>
			</div>
		</section>
		<Footer/>
	</div>
</template>

<script>
import Footer from '../components/Footer'
import $ from "jquery"
import Init from '../components/Init'
import ModalVideo from 'modal-video'
import 'modal-video/css/modal-video.min.css'
import Rellax from 'rellax'
//import { smoothScroll } from '../assets/js/smoothScroll'

export default {
	name: 'unjourjemesuischoisi',
	components:{
		Footer
	},
	mounted() {			
		$(function(){
			setTimeout(() => {
				//smoothScroll('#unjourjemesuischoisi');
			}, 100);
			
		});

		Init.methods.reveal()
		new Rellax('.rellax');
		new ModalVideo('.js-modal-btn');

		$(window).scroll(function(){
			$("header img").css("opacity", 1 - $(window).scrollTop() / $(window).height());
		});
	}
}
</script>