import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Unjour from '../views/Unjour.vue'
import Mcv from '../views/Mcv.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    /*meta: {
      title: 'Michael Vincent - Un jour je me suis choisi',
      metaTags: [
        {
          name: 'description',
          content: 'Michaël Vincent a passé sa jeunesse dans les rues de Montréal à consommer et à vendre de la drogue pour les gangs de rue et les motards.'
        },
        {
          property: 'og:description',
          content: 'Michaël Vincent a passé sa jeunesse dans les rues de Montréal à consommer et à vendre de la drogue pour les gangs de rue et les motards.'
        },
        {
          property: 'og:image',
          content: '@/assets/images/Website_Image_Un_Jour.png'
        },
        {
          property: 'og:image:secure_url',
          content: '@/assets/images/Website_Image_Un_Jour.png'
        },
        {
          property: 'og:image:width',
          content: '1200'
        },
        {
          property: 'og:image:height',
          content: '630'
        },
      ]
    }*/
  },
  {
    path: '/qui-est-michael-vincent',
    name: 'about',
    component: About,
    /*meta: {
      /*title: 'Michael Vincent - Qui est Michael Vincent?',
      metaTags: [
        {
          name: 'description',
          content: 'Michaël Vincent a grandi avec sa mère, Suzanne, à Drummondville et son père, qui vivait à Montréal, qu’il voyait occasionnellement. Un week-end de printemps, il s’est rendu à Montréal en autobus pour le voir, mais il ne s’est jamais pointé pour venir le chercher.'
        },
        {
          property: 'og:description',
          content: 'Michaël Vincent a grandi avec sa mère, Suzanne, à Drummondville et son père, qui vivait à Montréal, qu’il voyait occasionnellement. Un week-end de printemps, il s’est rendu à Montréal en autobus pour le voir, mais il ne s’est jamais pointé pour venir le chercher.'
        }
      ]
    }*/
  },
  {
    path: '/un-jour-je-me-suis-choisi',
    name: 'unjourjemesuischoisi',
    component: Unjour,
    /*meta: {
      title: 'Michael Vincent - Un jour je me suis choisi, La conférence',
      metaTags: [
        {
          name: 'description',
          content: '« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle.'
        },
        {
          property: 'og:description',
          content: '« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle.'
        }
      ]
    }*/
  },
  {
    path: '/mcv',
    name: 'mcv',
    component: Mcv,
    /*meta: {
      title: 'Michael Vincent - Un jour je me suis choisi, La conférence',
      metaTags: [
        {
          name: 'description',
          content: '« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle.'
        },
        {
          property: 'og:description',
          content: '« Un jour me me suis choisi » est un véritable message d’espoir s’adressant tant aux gens d’affaires qu’aux élèves, en passant par les professionnels et les individus désirant vivre une croissance personnelle.'
        }
      ]
    }*/
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    /*meta: {
      title: 'Michael Vincent - Me joindre',
      metaTags: [
        {
          name: 'description',
          content: 'Si vous souhaitez ograniser une conférence dans votre établissement, contactez-nous afin de valider les disponibilités.'
        },
        {
          property: 'og:description',
          content: 'Si vous souhaitez ograniser une conférence dans votre établissement, contactez-nous afin de valider les disponibilités.'
        }
      ]
    }*/
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) { 
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 300);
    next();
});

router.afterEach(() => {
  document.querySelector('body').style.height = 'auto';
  
  /*Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'Michael Vincent - Un jour je me suis choisi';
  });*/
})


export default router
