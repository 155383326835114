import $ from "jquery"


export default{
	methods:{
		loading(){
			var counter = 0;
			var c = 0;
			var i = setInterval(function(){
				$(".loading .counter h1").html(c + "%");
				$(".loading .counter hr").css("width", c + "%");

				counter++;
				c++;

				if(counter == 101) {
					clearInterval(i);
					$(".loading").addClass('loaded');
				}
			}, 5);
		},
		reveal(){
			var elements;
			var windowHeight;

			function restart() {
				elements = document.querySelectorAll('.textreveal, .imgreveal, .blockreveal');
				windowHeight = window.innerHeight;
			}

			function checkPosition() {
				for (var i = 0; i < elements.length; i++) {
					var element = elements[i];
					var positionFromTop = elements[i].getBoundingClientRect().top;

					if (positionFromTop - windowHeight <= 0) {
						element.classList.add('is-inview');
					}
				}
			}

			window.addEventListener('scroll', checkPosition);
			window.addEventListener('resize', restart);

			restart();
			checkPosition();
		},	
	}
}