<template>
	<div id="about">
		<vue-headful
            title="Michael Vincent - Qui est Michael Vincent?"
            description="Michaël Vincent a grandi avec sa mère, Suzanne, à Drummondville et son père, qui vivait à Montréal, qu’il voyait occasionnellement. Un week-end de printemps, il s’est rendu à Montréal en autobus pour le voir, mais il ne s’est jamais pointé pour venir le chercher."
			og:description="Michaël Vincent a grandi avec sa mère, Suzanne, à Drummondville et son père, qui vivait à Montréal, qu’il voyait occasionnellement. Un week-end de printemps, il s’est rendu à Montréal en autobus pour le voir, mais il ne s’est jamais pointé pour venir le chercher."
        />
		<header>
			<div class="container">
				<h1 class="rellax" data-rellax-speed=".5">QUI EST <br>MICHAËL<br> VINCENT?</h1>
			</div>
		</header>
		<section id="presentation">
			<div class="container">
				<p><span>M</span>ichaël Vincent a grandi en partie à Drummondville avec sa mère Suzanne et en partie à Montréal avec son père, qu’il ne voyait qu’occasionnellement. Tout à véritablement commencé un week-end de printemps alors que Michaël se rendait en autobus à Montréal pour visiter son père. Ce dernier ne s’est malheureusement jamais pointé à la station pour venir le chercher comme prévu. Les longues minutes, voire heures, d’attente auront valu à Michaël la rencontre de deux hommes dans la vingtaine, lesquels ont mis peu de temps à le convaincre de les suivre.</p>
				<img src="@/assets/images/05.jpg"/>
				<p>Michaël se souvient d’avoir été impressionné par leur allure de gangsters et d’avoir sans retenue décidé de les accompagner. Ils l’ont amené dans un bar de danseuses et fait boire sa première bière avant de l’initier à la vente de drogues. Au moment où il s’apprêtait à quitter les deux hommes pour retrouver son père, Michaël s’est vu offrir la possibilité de se joindre à eux le lendemain alors qu’ils venaient tout juste de lui faire miroiter l’accès à une importante somme d’argent. Répondant par la positive à cette invitation, Michaël était sans savoir que cette décision qu’il a prise allait être le prélude à une multitude d’épreuves dont il ignorait la noirceur.
				</p>
				<p>Michaël a d’abord agi à titre de « poteau », c’est-à-dire qu’il était chargé de garder la drogue dans un sac pendant que les vendeurs effectuaient leurs transactions. Puis, il est devenu vendeur à 15 ans, il gagnait déjà environ 150000$ par année. Cependant, il ne faisait malheureusement pas que vendre…</p>
				<p>À 11 ans, il a commencé à sniffer de la coke et, à 14 ans, il avait déjà essayé toutes les drogues qui existaient. Dans la rue, il a été victime de violence et a été témoin de tentatives de meurtre; l’enfer de la drogue était devenu son mode de vie. Pire encore, il s’est mis à consommer de plus en plus, jusqu’à devenir son meilleur client.</p>
				<p>Un soir, l’un des deux hommes qui l’avaient recruté a reçu une balle sous le menton. Apeuré et couvert de sang, Michaël s’est enfui. Plus tard, quatre personnes d’un autre clan s’en sont prises à lui, couteau en main, et l’ont obligé à monter à bord d’une voiture.</p>
				<p class="quote yellow">
					« Si j’ai réussi à m’en sortir, c’est parce qu’UN JOUR, JE ME SUIS CHOISI. Mais c'est aussi parce que j’ai croisé de bonnes personnes sur mon chemin. Aujourd’hui, c’est à mon tour de DONNER au suivant. Je veux être une source d’inspiration, et ma conférence est l’une des façons d’y arriver. »
				</p>
				<p>Après huit ans de vente de drogues, des proches inquiets pour sa santé ont demandé à Michaël Vincent d’entamer une thérapie. Entre 18 et 20 ans, il a tenté de quitter le milieu et d’aller en cure de désintoxication à trois reprises, mais sans succès.</p>
				<p>C’est finalement durant son quatrième séjour, à 21 ans, qu’il a réalisé qu’il devait mettre un terme à cette dépendance. C’est à ce moment QU’IL S’EST CHOISI, craignant que ses habitudes de consommation finissent par avoir raison de lui, de sa raison.
				</p>
				<p>Il est d’abord retourné vivre à Drummondville, puis, à 28 ans, Michaël a commencé à travailler comme homme à tout faire pour Les Équités Temcap, une compagnie exerçant dans le domaine de l’immobilier. Après avoir gravi les échelons, il est devenu propriétaire du Complexe Sacré-Cœur à Victoriaville, un établissement comportant plusieurs salles de réception.</p>
				<p>Dans l’optique de redonner au suivant, Michaël a créé Séjour 21, un projet permettant à dix personnes par années d’être hébergées gratuitement pendant 21 jours consécutifs pour suivre différents ateliers de croissance personnelle.</p>
			</div>
		</section>
		<section id="revuesdepresse">
			<div class="container">
				<h3 class="yellow">Revues de presse</h3>
				<ul>
					<li v-for="presse in presseList" v-bind:key="presse.id">
						<a v-bind:href="presse.fields.Lien" target="_blank">
							<u>{{ presse.fields.Titre }}</u>
							{{ presse.fields.Référence }}
						</a>
					</li>
				</ul>
			</div>
		</section>
		<section class="biglink">
			<div class="container">
				<router-link to="/un-jour-je-me-suis-choisi">Un jour, je me suis choisi.</router-link>
			</div>
		</section>
		<Footer/>
	</div>
</template>
<script>
import Footer from '../components/Footer'
import $ from "jquery"
import Init from '../components/Init'
import ModalVideo from 'modal-video'
import 'modal-video/css/modal-video.min.css'
import Rellax from 'rellax'
//import { smoothScroll } from '../assets/js/smoothScroll'
import axios from 'axios'

export default{
	name: 'about',
	data: function(){
		return{
			presseList: null
		}
	},
	components:{
		Footer
	},
	mounted() {			
		$(function(){
			//smoothScroll('#about');
		});

		Init.methods.reveal()
		new Rellax('.rellax');
		new ModalVideo('.js-modal-btn',{ autoplay: 1 });

		$(window).scroll(function(){
			$("header h1").css("opacity", 1 - $(window).scrollTop() / $(window).height());
		});

		// Init variables
		var ref = this;
		var app_key = process.env.VUE_APP_AIRTABLEAPIKEY;
		var app_id = process.env.VUE_APP_AIRTABLEAPPID;
		var config = {
			headers: {'Authorization': "Bearer " + app_key}
		};

		axios.get(
			"https://api.airtable.com/v0/"+app_id+"/Revues%20de%20presse?view=Grid%20view",
			config
		).then(function(response){
			ref.presseList = response.data.records
		})/*.catch(function(error){
			console.log(error)
		})*/
	}
}
</script>
